import { Link } from 'react-router-dom';
import { Projects } from '../../generated/graphql';
import { customerLogoUrl } from '../../models/ProjectTypes';
import { Avatar, AvatarProps } from './Avatar';

type ProjectNameAndAvatarProps = {
  project: Projects;
} & AvatarProps;

export const ProjectNameAndAvatar = ({ project }: ProjectNameAndAvatarProps) => {
  return (
    <Link to={`/projects/${project.id}`} className='flex items-center'>
      {project.customer?.logo && <Avatar url={customerLogoUrl(project.customer!)} />}
      <span className='ml-4 self-center text-neutral-800'>{project.name}</span>
    </Link>
  );
};
