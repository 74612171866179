import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HellonSymbol } from '../../logos/HellonSymbol';
import { Spark } from '../../logos/Spark';
import { useAuth } from '../../providers/Auth';
import DefinedRoutes from '../../routes/DefinedRoutes';
import { GlobalSearch } from '../Search/GlobalSearch';

const navigation = [
  { name: 'Dashboard', href: DefinedRoutes.DASHBOARD, current: false },
  { name: 'Resourcing', href: DefinedRoutes.RESOURCING, current: false },
  { name: 'People', href: DefinedRoutes.PEOPLE, current: false },
  { name: 'Projects', href: DefinedRoutes.PROJECTS, current: false },
  { name: 'Payroll', href: DefinedRoutes.PAYROLL, current: false }
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export const Navbar = () => {
  const auth = useAuth();
  const { pathname } = useLocation();

  const [globalSearch, setGlobalSearch] = useState<boolean>(false);

  const onKeyDown = (e: any) => {
    if (e.metaKey && e.which === 75) {
      setGlobalSearch(true);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <Disclosure as='nav' className='bg-transparent'>
      {({ open }) => (
        <>
          <div className='mx-auto max-w-screen-2xl px-2 sm:px-6 lg:px-8 bg-transparent'>
            <div className='relative flex h-16 items-center justify-between '>
              <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                {/* Mobile menu button*/}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>

              <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                <div className='w-8 mr-16 items-center'>
                  <HellonSymbol className='fill-white' />
                </div>
                <div className='hidden sm:ml-6 sm:block'>
                  <div className='flex space-x-4'>
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          pathname === item.href
                            ? 'text-white font-extrabold'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className='flex items-center rounded-md bg-neutral-800 hover:bg-neutral-600 w-64 h-8 cursor-pointer justify-between'
                onClick={() => {
                  setGlobalSearch(true);
                }}
              >
                <MagnifyingGlassIcon className='w-5 m-1 ml-3 text-slate-300' />
                <span className='ml-3 text-sm'>Quick search ...</span>

                <kbd className='mr-3 inline-flex items-center align-middle justify-items-center self-center rounded border border-gray-500 px-2 font-sans text-sm font-medium text-gray-400'>
                  ⌘ K
                </kbd>
              </div>
              <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                {/* Profile dropdown */}
                <Menu as='div' className='relative ml-3'>
                  <div>
                    <Menu.Button className='flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                      <span className='sr-only'>Open user menu</span>
                      <img className='h-8 w-8 rounded-full' src={auth?.user.picture} alt='' />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      <Menu.Item>
                        {({ active }) => (
                          /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                          <a
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                            onClick={() => {
                              auth?.signOut();
                            }}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='space-y-1 px-2 pt-2 pb-3'>
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as='a'
                  href={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
          <div className='w-full'>
            <Spark small />
          </div>

          <GlobalSearch
            open={globalSearch}
            requestClose={() => {
              setGlobalSearch(false);
            }}
          />
        </>
      )}
    </Disclosure>
  );
};
