import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import {
  add,
  differenceInCalendarDays,
  formatDuration,
  formatISO,
  intervalToDuration,
  isBefore,
  parseISO
} from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { EmployeeAvatarAndName } from '../components/Avatars/EmployeeAvatar';
import { EmptyPageLayout } from '../components/PageLayout/EmptyPageLayout';
import { Assignments, Employee, useResourcingQuery } from '../generated/graphql';
import { formatDate } from '../utils/formatters';

type BarProps = {
  employee: any;

  maxRange: Date;
};
const Bar = ({ employee, maxRange }: BarProps) => {
  const r = useRef<HTMLDivElement>(null);
  const [rect, setRect] = useState<DOMRect>(new DOMRect(0, 0, 0, 0));

  const days = differenceInCalendarDays(maxRange, Date.now());

  useEffect(() => {
    const handleResize = () => {
      if (r.current) {
        const { current } = r;
        const boundingRect = current.getBoundingClientRect();

        setRect(boundingRect);
      }
    };

    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('load', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, [setRect]);

  var width: number = 0;

  const calcWidth = (a: Assignments) => {
    if (rect.width > 0) {
      const dayWidth = rect.width / days;
      const daystoRender = differenceInCalendarDays(parseISO(a.end_date), Date.now());
      width = Math.round(daystoRender * dayWidth);
      return width;
    }
    return 0;
  };
  return (
    <div className='grid grid-cols-6 w-full content-center min-h-[40px]'>
      <EmployeeAvatarAndName employee={employee} size={5} className='self-center col-span-1' />
      {employee.assignments.map((a: Assignments) => {
        return (
          <>
            <div key={a.id} ref={r} className='col-span-5'>
              <div
                style={{ width: calcWidth(a) }}
                className={`bg-neutral-800 border-2 border-neutral-800 h-6 pl-2 m-2 rounded-full text-sm text-neutral-50 whitespace-nowrap overflow-ellipsis overflow-hidden flex justify-between`}
              >
                <div>
                  {formatDate(a.end_date)} &rsaquo;&rsaquo; {a.project?.name}
                </div>
                <div className='bg-neutral-50 w-3 h-3 rounded-full self-center mr-1'></div>
              </div>
            </div>
            <div className='col-span-1' />
          </>
        );
      })}
    </div>
  );
};

export const Resourcing = () => {
  const rangeOptions = [
    {
      id: '1-month',
      value: 31,
      label: '1 month'
    },
    {
      id: '3-months',
      value: 3 * 31,
      label: '3 months'
    },
    {
      id: '6-months',
      value: 6 * 31,
      label: '6 months'
    }
  ];
  const [range, setRange] = useState(rangeOptions[0]);

  const { data, loading } = useResourcingQuery({
    variables: { report_month: formatISO(Date.now()) }
  });

  const filter = (employees: Employee[], end: Date) => {
    return employees
      .map((e) => {
        const ass = e.assignments
          .map((a) => (isBefore(parseISO(a.end_date), end) ? a : null))
          .filter((a) => a !== null)
          .sort((a, b) => parseISO(a?.end_date).getTime() - parseISO(b?.end_date).getTime());

        const emp = { ...e };
        emp.assignments = ass as Assignments[];

        return ass.length > 0 ? emp : null;
      })
      .filter((e) => e !== null);
  };

  const employees = data?.employee ?? ([] as any);
  const withOutAssignment = employees.filter((e: any) => e.assignments?.length === 0);

  const endingInNextX = filter(employees, add(Date.now(), { days: range.value }));

  const customFormatDuration = ({ start, end }: { start: number; end: number }) => {
    const durations = intervalToDuration({ start, end });
    return formatDuration(durations, { format: ['months'] });
  };

  type TagProps = {
    value: any;
  };
  const Tag = ({ value }: TagProps) => {
    const bg = range.id === value.id ? 'bg-neutral-900 text-white' : 'bg-transparent text-black';

    return (
      <div
        className={`flex align-middle ${bg} border-neutral-900 border pt-1 pb-1 pl-2 pr-2 rounded-full mr-2 cursor-pointer`}
        onClick={() => setRange(value)}
      >
        <span className='mr-3 ml-3'>{value.label}</span>
      </div>
    );
  };

  return (
    <>
      <EmptyPageLayout title="Today's status" loading={loading}>
        <div className='flex flex-shrink-0 justify-between'>
          <div className='w-96 text-2xl font-normal'>Without a billable project</div>
        </div>
        <div className='h-full w-full p-1 mt-10'>
          <div className='grid grid-cols-5 gap-3'>
            {withOutAssignment.map((e: any) => (
              <div className='col-span-1 border-2 border-neutral-700 rounded-lg p-1 hover:bg-neutral-100'>
                <EmployeeAvatarAndName employee={e as any} size={8} />
              </div>
            ))}
          </div>
        </div>
        <div className='flex flex-shrink-0 mt-10'>
          <div className='text-2xl font-normal'>Ending in next</div>
          <div className='ml-3 inline-flex'>
            {rangeOptions.map((option) => (
              <div className='self-end'>
                <Tag value={option} />
              </div>
            ))}
          </div>
        </div>
        <div className='h-full w-full p-1 mt-10'>
          <hr />
          {endingInNextX.map((e: any) => (
            <>
              <Bar employee={e} maxRange={add(Date.now(), { days: range.value })} />
              <hr />
            </>
          ))}
        </div>
        <div className='mt-10 w-full text-2xl font-normal flex content-center'>
          <ChevronDoubleRightIcon className='w-4 h-4 self-center mr-3 ml-3' />
          <span>
            {employees.length - endingInNextX.length + 2} employees a billable project continuing
            after {customFormatDuration({ start: 0, end: range.value * 24 * 3600 * 1000 })}
          </span>
        </div>
      </EmptyPageLayout>
    </>
  );
};
