import { isAfter, isSameDay, isSameMonth, parseISO, startOfMonth } from 'date-fns';
import { Employee } from '../generated/graphql';

export const employeeFullname = (employee: Employee): string => {
  return `${employee.last_name} ${employee.first_name}`;
};

export const employeeIsNonBillable = (employee: Employee): boolean => {
  return (
    employee.metadata?.non_billable_employee === undefined ||
    employee.metadata.non_billable_employee === true
  );
};

export const employeeIsBillable = (employee: Employee): boolean => {
  return (
    employee.metadata.non_billable_employee === false ||
    employee.metadata.non_billable_employee === undefined
  );
};

export const employeeIsActiveDuringMonth = (employee: Employee, month: Date): boolean => {
  const sd = parseISO(employee.employment_start);
  if (isSameDay(sd, startOfMonth(month)) || isAfter(sd, startOfMonth(month))) {
    return true;
  }
  if (employee.employment_end === null) {
    return true;
  }
  const ed = parseISO(employee.employment_end);

  if (isSameMonth(ed, month) || isAfter(ed, startOfMonth(month))) {
    return true;
  }

  return false;
};

export const employeeHasLeftTheBuilding = (employee: Employee): boolean => {
  if (employee.employment_end === undefined) {
    return false;
  }
  return employee.employment_end !== null;
};

export const employeeBasicInfoOk = (employee: Employee): boolean => {
  if (
    employee.employment_start != null &&
    employee.employee_allocations != null &&
    employee.employee_allocations.length > 0
  ) {
    return (
      employee.employee_allocations.find((a) => a.start_date === employee.employment_start!) !==
      undefined
    );
  }
  return false;
};

export const employeeInitials = (employee: Employee): string => {
  return `${employee.last_name?.charAt(0)}${employee.first_name?.charAt(0)}`;
};

export const filterEmployees = (employees: Employee[], filter: string): Employee[] => {
  return employees.filter((employee) =>
    employeeFullname(employee).toLocaleLowerCase().includes(filter.toLocaleLowerCase())
  );
};

export const sortByLastName = (employees: Employee[]): Employee[] => {
  return [...employees].sort((a, b) =>
    employeeFullname(a).localeCompare(employeeFullname(b), 'FI')
  );
};

export const nonBillableEmployees = (employees: Employee[]): Employee[] => {
  return [...employees].filter(employeeIsNonBillable);
};

export const billableEmployees = (employees: Employee[]): Employee[] => {
  return [...employees].filter((e) => employeeIsBillable(e));
};

export const billableAndActiveInMonth = (employees: Employee[], month: Date): Employee[] => {
  return [...employees].filter((e) => {
    return employeeIsBillable(e) && employeeIsActiveDuringMonth(e, month);
  });
};

export const activeInMonth = (employees: Employee[], month: Date): Employee[] => {
  return [...employees].filter((e) => {
    return employeeIsActiveDuringMonth(e, month);
  });
};
