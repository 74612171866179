import { useState } from 'react';

type SearchProps = {
  onFilterUpdated?: (filter: string) => void;
  placeholder?: string;
};

export const Search = ({ onFilterUpdated, placeholder }: SearchProps) => {
  const [filter, setFilter] = useState<string>('');

  return (
    <div className='sm:flex justify-end flex-2'>
      <div className='relative mt-1'>
        <input
          value={filter}
          onChange={(event) => {
            if (onFilterUpdated) {
              setFilter(event.target.value);
              onFilterUpdated(event.target.value);
            }
          }}
          type='text'
          name='search'
          id='search'
          placeholder={`Search ${placeholder}`}
          className='block w-80 h-10 p-4 rounded-md pr-12 border border-gray-300 focus:border-magenta-300 focus:ring-indigo-300 hover:shadow-gray-500/40'
        />
      </div>
    </div>
  );
};
