import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { add, isSameMonth, sub } from 'date-fns';
import React, { useContext } from 'react';
import { MonthContext } from '../../providers/MonthContext';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';
import { SideDrawer } from '../Drawers/SideDrawer';
import { months } from '../Inputs/MonthSelect';
import { Spinner } from '../Spinner/Spinner';

interface EmptyPageLayoutProps {
  title?: string;
  titleComponent?: JSX.Element | null;
  children?: React.ReactNode;
  rightComponent?: JSX.Element;
  loading?: boolean;
}

const YearNavigator = () => {
  const { selectedMonth, setSelectedMonth } = useContext(MonthContext);

  return (
    <div className='ml-20 flex'>
      <ChevronLeftIcon
        className='w-5 justify-center items-center h-full text-gold'
        onClick={() => {
          const prevYear = sub(selectedMonth, { years: 1 });
          setSelectedMonth(prevYear);
        }}
      />
      <div
        className='flex justify-center items-center h-full mr-4 ml-4 text-white'
        onClick={() => {
          setSelectedMonth(new Date());
        }}
      >
        {selectedMonth.getFullYear()}
      </div>
      <ChevronRightIcon
        className='w-5 justify-center items-center h-full text-gold'
        onClick={() => {
          const prevYear = add(selectedMonth, { years: 1 });
          setSelectedMonth(prevYear);
        }}
      />
    </div>
  );
};

export const EmptyPageLayout = ({
  title,
  titleComponent,
  children,
  rightComponent,
  loading = false
}: EmptyPageLayoutProps) => {
  const {
    state: { drawer },
    dispatch
  } = useContext(SystemUiContext);

  const { selectedMonth, setSelectedMonth } = useContext(MonthContext);

  return (
    <div className='mx-auto max-w-screen-2xl px-2 sm:px-6 lg:px-8 sm:pb-6 lg:pb-8'>
      <div className='flex justify-between items-center'>
        {title ? <div className='text-2xl lg:text-3xl font-bold my-11'>{title}</div> : null}
        {titleComponent}
        {rightComponent}
      </div>
      <div className='flex justify-center ml-2 h-9 text-m mb-0 cursor-pointer'>
        {months.map((m) => {
          const selectedTab = m.value === selectedMonth.getMonth();
          const tabAsDate = new Date(selectedMonth);
          tabAsDate.setMonth(m.value);
          return (
            <div
              key={m.value}
              className={`rounded-t-lg w-1/12 ${selectedTab ? 'bg-white' : 'bg-white/10'} ${
                selectedTab
                  ? 'text-black'
                  : isSameMonth(tabAsDate, Date.now())
                  ? 'text-gold'
                  : 'text-white/50'
              } font-medium mr-1 text-center self-center h-full flex justify-center items-center`}
              onClick={() => {
                const d = new Date(selectedMonth);
                d.setMonth(m.value);
                setSelectedMonth(d);
              }}
            >
              <span>{m.label}</span>
            </div>
          );
        })}
        <YearNavigator />
      </div>
      <div className='min-h-[calc(100vh-36px-64px-88px-64px)] shadow rounded-lg bg-[#FFFFFF] p-4 sm:p-8 lg:p-16 text-ae-black'>
        {loading ? (
          <div className='flex justify-center mt-16'>
            <Spinner />
          </div>
        ) : (
          children
        )}
      </div>
      <SideDrawer
        isOpen={drawer.open}
        onClose={() => {
          dispatch?.({
            type: SystemUiActions.SHOW_DRAWER,
            payload: {
              open: false,
              content: null
            }
          });
        }}
        title={drawer.title}
        description={drawer.description}
      >
        {drawer.content}
      </SideDrawer>
    </div>
  );
};
