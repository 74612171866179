import { LinkIcon } from '@heroicons/react/20/solid';
import { ArrowPathIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline';

import { formatISO, lastDayOfMonth } from 'date-fns';
import { useContext, useEffect, useState } from 'react';

import { ModalDialog } from '../../components/Modals/ModalDialog';
import { ExtendableTable } from '../../components/Tables/ExtendableTable';
import { Employee, useMonthlyPayrollReportQuery } from '../../generated/graphql';
import {
  billableAndActiveInMonth,
  employeeFullname,
  sortByLastName
} from '../../models/EmployeeTypes';

import { createColumnHelper } from '@tanstack/react-table';
import {
  currencyColumnGenerator,
  employeeAvatarFullnameGenerator,
  hoursColumnGenerator
} from '../../components/Tables/ColumnTypes';
import { useGoogleSheetExport } from '../../providers/GoogleSpreadSheet';
import { MonthContext } from '../../providers/MonthContext';

import { Content, PanelTab } from '../../components/Tabs/Panels';

export const MonthlyPayroll = () => {
  const [exporting, exportSheet, sheetId] = useGoogleSheetExport();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { selectedMonth } = useContext(MonthContext);

  const { loading, data } = useMonthlyPayrollReportQuery({
    variables: {
      report_month: formatISO(lastDayOfMonth(selectedMonth))
    }
  });

  useEffect(() => {
    setModalOpen(sheetId != null);
  }, [sheetId]);

  const empl = (data?.employee || []) as Employee[];
  const activeEmployees = billableAndActiveInMonth(empl, selectedMonth);
  const transformedData = sortByLastName(activeEmployees);

  const columnHelper = createColumnHelper<Employee>();

  const columns = [
    columnHelper.accessor((row) => row, {
      id: 'employee',
      ...employeeAvatarFullnameGenerator(),
      footer: () => 'TOTALS'
    }),
    columnHelper.accessor((row) => row.payroll_reports[0]?.external_billing, {
      id: 'external_billing',
      ...currencyColumnGenerator('Customer billing', true)
    }),

    columnHelper.accessor((row) => row.payroll_reports[0]?.internal_billing, {
      id: 'internal_billing',
      ...currencyColumnGenerator('Billing for bonus', true)
    }),

    columnHelper.accessor((row) => row.payroll_reports[0]?.billing_for_bonus, {
      id: 'billing_for_bonus',
      ...currencyColumnGenerator('Bonus basis (70%)', true)
    }),

    columnHelper.accessor((row) => row.payroll_reports[0]?.base_salary, {
      id: 'base_salary',
      ...currencyColumnGenerator('Base salary', true)
    }),

    columnHelper.accessor((row) => row.payroll_reports[0]?.salary_bonus_part, {
      id: 'salary_bonus_part',
      ...currencyColumnGenerator('Bonus salary', true)
    }),

    columnHelper.accessor((row) => row.payroll_reports[0]?.balance_change, {
      id: 'balance_change',
      ...hoursColumnGenerator('Balance change', true)
    })
  ];

  const buttonLogo = () => {
    if (exporting) {
      return <ArrowPathIcon className='mr-5 h-6 w-6 text-white animate-spin' aria-hidden='true' />;
    } else {
      return <CloudArrowUpIcon className='mr-5 h-6 w-6' />;
    }
  };

  const payrollMonthString = formatISO(lastDayOfMonth(selectedMonth), {
    format: 'extended',
    representation: 'date'
  });
  const exportDateString = formatISO(new Date());

  const values: any = [];
  values.push([
    'Employee',
    'Employment start',
    'Working days',
    'Worked days',
    'Balance change',
    'Bonus salary',
    'Base salary',
    'Calculated base salary',
    'Total Salary',
    'Comment'
  ]);
  transformedData.forEach((e) => {
    var row = [
      employeeFullname(e),
      e.employment_start,
      e.monthly_hours_report[0]?.working_days,
      e.monthly_hours_report[0]?.worked_days,
      e.payroll_reports[0]?.balance_change,
      e.payroll_reports[0]?.salary_bonus_part,
      e.payroll_reports[0]?.base_salary,
      e.payroll_reports[0]?.calculated_salary_base,
      e.payroll_reports[0]?.calculated_salary_base + e.payroll_reports[0]?.salary_bonus_part,
      ''
    ];
    values.push(row);
  });

  values.push([
    'Totals',
    '',
    '',
    '',
    `=SUM(E2:E${values.length})`,
    `=SUM(F2:F${values.length})`,
    `=SUM(G2:G${values.length})`,
    `=SUM(H2:H${values.length})`,
    `=SUM(I2:I${values.length})`,
    ''
  ]);

  return (
    <>
      <PanelTab
        action={
          <button
            type='button'
            className='ml-4 inline-flex items-center rounded-md border border-transparent bg-royal-dark-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-slate-500'
            onClick={() => {
              if (!exporting) {
                exportSheet(
                  `${payrollMonthString}-Payroll (Exported on ${exportDateString})`,
                  values
                );
              }
            }}
          >
            {buttonLogo()}
            Export to Google sheets
          </button>
        }
      >
        Payroll
      </PanelTab>
      <Content>
        <ExtendableTable
          columns={columns}
          data={transformedData}
          loading={loading}
          hasFooter={true}
        />

        <ModalDialog
          isOpen={modalOpen}
          title='Open'
          description={`Do you want the open the newly exported payroll data in google sheets?`}
          cancelLabel={'Cancel'}
          actionLabel={'Open'}
          icon={<LinkIcon className='text-gold' />}
          onClose={() => {
            setModalOpen(false);
          }}
          onAction={() => {
            window.open(
              'https://docs.google.com/spreadsheets/d/' + sheetId,
              '_blank',
              'noreferrer'
            );
            setModalOpen(false);
          }}
        />
      </Content>
    </>
  );
};
