/* eslint-disable @typescript-eslint/no-unused-vars */

import { gql } from '@apollo/client';

const GET_CUSTOMERS = gql`
  query CustomerDetails($where: customers_bool_exp) {
    customers(where: $where) {
      id
      name
      business_id
      logo
      is_own
      projects {
        id
        name
        po
        start_date
        end_date
      }
      projects_aggregate {
        nodes {
          id
          assignments {
            external_price
            time_entries_aggregate {
              aggregate {
                sum {
                  hours
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CUSTOMER_AND_PROJECTS = gql`
  query Customers {
    customers {
      id
      name
      business_id
      logo
      is_own
      projects {
        id
        name
        start_date
        end_date
        po
        assignments {
          id
          employee {
            id
            avatar
            last_name
            first_name
            metadata
          }
        }
      }
    }
  }
`;

export const GET_PROJECT_AND_ASSIGNMENT_DETAILS = gql`
  query AssignmentDetails(
    $where: assignments_bool_exp
    $time_entries_aggr_where: time_entries_bool_exp
  ) {
    assignments(where: $where) {
      id
      start_date
      end_date
      external_price
      internal_price
      allocation
      comment
      employee {
        id
        last_name
        first_name
        avatar
        metadata
      }
      time_entries_aggregate(where: $time_entries_aggr_where) {
        aggregate {
          sum {
            hours
          }
        }
      }
    }
  }
`;

const GET_PROJECTS = gql`
  query Projects($where: projects_bool_exp) {
    projects(where: $where) {
      id
      name
      start_date
      end_date
      po
      customer {
        id
        name
        logo
      }
      assignments {
        id
        start_date
        end_date
        external_price
        internal_price
        po
        employee {
          id
          last_name
          first_name
          avatar
          metadata
        }
      }
    }
  }
`;

const TIME_ENTRIES_COUNT = gql`
  query TimeEntryCount($where: assignments_bool_exp) {
    assignments(where: $where) {
      id
      time_entries_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const PROJECT_AGGREGATED_TIME_ENTRIES = gql`
  query ProjectAggregatedTimeEntres($id: uuid) {
    projects_aggregate(where: { id: { _eq: $id } }) {
      nodes {
        assignments_aggregate {
          nodes {
            time_entries_aggregate {
              aggregate {
                sum {
                  hours
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GLOBAL_SEARCH = gql`
  query GlobalSearch($q: String) {
    employee(where: { _or: [{ last_name: { _ilike: $q } }, { first_name: { _ilike: $q } }] }) {
      id
      first_name
      last_name
      avatar
    }

    projects(where: { name: { _ilike: $q } }) {
      id
      name
      customer {
        name
        logo
      }
    }
  }
`;

const GET_EMPLOYEES = gql`
  query Employees($where: employee_bool_exp) {
    employee(where: $where) {
      id
      email
      first_name
      last_name
      avatar
      employment_start
      employment_end
      metadata
      employee_allocations {
        id
        start_date
        allocation
        metadata
        comment
      }
    }
  }
`;

export const GET_EMPLOYEE_WITH_ASSIGNMENTS = gql`
  query EmployeesWithAssignments($where: employee_bool_exp) {
    employee(where: $where) {
      id
      email
      first_name
      last_name
      avatar
      employment_start
      metadata
      balance {
        rolling_balance
      }
      employee_allocations {
        id
        start_date
        allocation
        metadata
        comment
      }
      assignments {
        id
        name
        start_date
        end_date
        project {
          id
          name
          customer {
            id
            logo
            name
          }
        }
      }
    }
  }
`;

const EMPLOYEE_DETAILS = gql`
  query EmployeesDetails($where: employee_bool_exp) {
    employee(where: $where) {
      id
      email
      first_name
      last_name
      employment_start
      employment_end
      metadata
      balance_corrections(order_by: { day: desc }) {
        id
        hours
        day
        comment
      }
      employee_allocations(order_by: { start_date: desc }) {
        id
        start_date
        allocation
        metadata
        comment
      }
    }
  }
`;

const COMPANY_MONTHLY_BILLING = gql`
  query CompanyMonthlyBillingByProjectAndEmployee($report_month: date) {
    company_monthly_billing_report(where: { report_month: { _eq: $report_month } }) {
      employee {
        id
        last_name
        first_name
        metadata
      }
      assignment {
        po
        external_price
      }
      project {
        customer {
          name
          business_id
        }
        name
        po
        sales {
          last_name
          first_name
        }
      }
      sales_comission
      employee_comission
      billing
      report_month
      working_days
      hours
    }
  }
`;

const EMPLOYEE_TIME_ENTRIES = gql`
  query EmployeeTimeEntries($start: date, $end: date, $id: uuid) {
    time_entries(
      where: {
        _and: { day: { _gte: $start, _lte: $end } }
        assignment: { employee_id: { _eq: $id } }
      }
    ) {
      hours
      day
      comment
      assignment {
        name
        id
        external_price
        internal_price
        project {
          id
          name
          type
          customer {
            name
            business_id
            logo
          }
        }
      }
    }
  }
`;

export const GET_PAYROLL_SETTINGS = gql`
  query PayrollSettings {
    salary_coeffs(order_by: { period: desc }) {
      base_salary
      bonus_percentage
      company_side_cost_coef
      day_wage
      hourly_wage
      period
    }
  }
`;

export const GET_MONTHLY_REPORT = gql`
  query MonthlyHoursReport($report_month: date) {
    employee {
      first_name
      id
      last_name
      avatar
      employment_start
      employment_end
      metadata
      employee_allocations {
        start_date
        allocation
        metadata
      }
      monthly_hours_report(where: { report_month: { _eq: $report_month } }) {
        billable_hours
        report_month
        company_hours
        holiday_hours
        sickleave_hours
        unpaid_hours
      }
    }

    employee_monthly_billing_report_aggregate(where: { report_month: { _eq: $report_month } }) {
      aggregate {
        sum {
          external_billing
        }
      }
    }
  }
`;

export const GET_MONTHLY_PAYROLL_REPORT = gql`
  query MonthlyPayrollReport($report_month: date) {
    employee {
      first_name
      id
      last_name
      avatar
      employment_start
      employment_end
      metadata
      employee_allocations {
        start_date
        allocation
        metadata
      }
      payroll_reports(where: { report_month: { _eq: $report_month } }) {
        balance_change
        base_salary
        billing_for_bonus
        calculated_salary_base
        external_billing
        internal_billing
        report_month
        salary_bonus_part
      }

      monthly_hours_report(where: { report_month: { _eq: $report_month } }) {
        billable_hours
        report_month
        company_hours
        holiday_hours
        sickleave_hours
        unpaid_hours
        working_days
        worked_days
      }
    }
  }
`;

const GET_PAID_HOLIDAYS = gql`
  query PaidHolidays {
    paid_holidays(order_by: { day: desc }) {
      id
      day
      comment
    }
  }
`;

const CALENDAR = gql`
  query Calendar($from: date, $to: date) {
    calendar(args: { from_date: $from, to_date: $to }) {
      day
      paid_holiday
      weekday
    }
  }
`;

const ASSIGNMENT_TIME_ENTRY_COUNT = gql`
  query AssignmentTimeEntryCount($assignment_ids: [uuid!]) {
    assignments(where: { id: { _in: $assignment_ids } }) {
      id
      time_entries_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const CUSTOMER_DETAIL_BILLING_REPORT = gql`
  query CustomerDetailedBillingReport($customer_id: uuid) {
    assignments(where: { project: { customer: { id: { _eq: $customer_id } } } }) {
      id
      external_price
      employee {
        id
        first_name
        avatar
        last_name
        metadata
      }
      project {
        id
        name
        start_date
        end_date
      }
      time_entries {
        id
        day
        hours
      }
    }
  }
`;

const RESOURCING = gql`
  query resourcing($report_month: date) {
    employee(
      where: {
        assignments: { project: { customer: { is_own: { _eq: false } } } }
        employment_end: { _is_null: true }
      }
    ) {
      id
      last_name
      first_name
      avatar
      assignments(
        where: {
          project: { customer: { is_own: { _eq: false } } }
          end_date: { _gt: $report_month }
        }
      ) {
        project {
          id
          name
          customer {
            name
          }
        }
        id
        start_date
        end_date
        allocation
      }
    }
  }
`;
